import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 

function MonitoringsfaseAfsluiten() {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = colors.orange;
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

    return (
      <div className="wrapper">
            <h1 className="text-2xl mb-4">Monitoringsfase Afsluiten</h1>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/nieuwe-melding/triage-doet-veiligheidsbeoordeling/dezelfde-soort/casushouder-overlegd-met-overdrachtspartij/triagebesluit/casushouder-keuze-vervolgstappen/contact-opnemen-betrokkenen/ico-mdo-dienst-kiezen")}>Terug</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/")}>Terug naar Startscherm</button>
      </div>
    );
  }

export default MonitoringsfaseAfsluiten;