import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 

function AmbtsHalveMelding() {
    const navigate = useNavigate();

    useEffect(() => {
      document.body.style.backgroundColor = colors.pink;
      return () => {
        document.body.style.backgroundColor = "";
      };
    }, []);

    return (
        <div className="wrapper">
            <h1 className="text-2xl mb-4">Ambtshalve melding aanmaken</h1>
            <p className='mb-4'>Wanneer tijdens monitoringsfase blijkt dat er onvoldoende herstel van veiligheid wordt gerealiseerd en de directbetrokkenen onvoldoende bereid zijn om mee te werken aan de hulpverlening die VT noodzakelijk acht. VT neemt dit besluit in ICO/MDO.</p> 
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties")}>Terug</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/")}>Terug naar Startscherm</button>
      </div>
    );
  }

export default AmbtsHalveMelding;