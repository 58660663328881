import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 


function DBVerwezenNaarKetenpartner() {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = colors.yellow;
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

    return (
      <div className="wrapper">
            <h1 className="text-2xl mb-4">DB wordt verwezen naar ketenpartner aan wie is overgedragen</h1>
            <button className='mt-4 w-full p-4 bg-yellow-300 rounded-md border-4 border-black font-medium' onClick={() => navigate("/direct-betrokkenen-belt-vt/db-verwezen-naar-ketenpartner/contact-in-contactjournaal-noteren")}>Volgende</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/direct-betrokkenen-belt-vt")}>Terug</button>
      </div>
    );
  }

export default DBVerwezenNaarKetenpartner;