import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 

function AanmakenAdvies() {
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.backgroundColor = colors.pink;
        return () => {
          document.body.style.backgroundColor = "";
        };
      }, []);

      
    return (
        <div className="wrapper h-screen md:h-auto py-6">
            <h1 className="text-2xl mb-4">Aanmaken advies/ ondersteuningsverzoek</h1>
            <p class='mb-2'>* De overdrachtspartij kan vragen om een advies of ondersteuningsverzoek. Indien de vraag van de overdrachtspartij gaat over de gestelde veiligheidsvoorwaarden of twijfels rondom veiligheid kan dit overleg plaatsvinden binnen de monitoringsfase en onderdeel van dit dossier bij VT zijn (zie blauw stroomschema).</p>
	        <p class='mb-2'>*Indien de vraag van de overdrachtspartij niet gaat over de gestelde veiligheidsvoorwaarden of twijfels rondom veiligheid wordt dit niet opgenomen in het dossier. Er wordt dan een advies of ondersteuningsverzoek aangemaakt.</p>
            <button class='mb-2 mt-4 font-bold text-left'>Wanneer geef je een Advies?</button>
            <ul class='ml-4 list-disc'>
                <li>Je geeft een advies aan de hulpverlener over zijn/haar handelingsmogelijkheden</li>
                <li>De informatie uit het advies deelt VT niet te delen met direct betrokkenen</li>
                <li>Advies aparte dienst en geen onderdeel van het lopende dossier, informatie uit het advies kan niet meegenomen worden in monitoring</li>
            </ul>
            <button class='mb-2 mt-4 font-bold text-left'>Wanneer maak je ondersteuningsverzoek aan?</button>
            <ul class='ml-4 list-disc'>
                <li>Een ondersteuningsverzoek is een vorm van advies</li>
                <li>Een ondersteuningsverzoek is een aparte dienst en geen onderdeel van het lopende dossier, informatie kan niet worden meegenomen in monitoring</li>
                <li>In een ondersteuningsverzoek sluit je aan bij een gesprek met DB, groot overleg, 1G1P, of bij casusoverleg ketenpartner op verzoek van ketenpartner</li>
            </ul>
            <button class='mb-2 mt-4 font-bold text-left'>Wat biedt de ondersteuning?</button>
            <ul class='ml-4 list-disc'>
                <li>de casusverantwoordelijke pakt het ondersteuningsverzoek op, tenzij het passender is dat een collega van md/triage dit doet</li>
            </ul>
            <button className='mt-4 mb-6 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties")}>Terug</button>
      </div>
    );
  }

export default AanmakenAdvies;