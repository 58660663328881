import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 

function AfrondingContact() {
    const navigate = useNavigate();
  
    useEffect(() => {
      document.body.style.backgroundColor = colors.pink;
      return () => {
        document.body.style.backgroundColor = "";
      };
    }, []);

    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">Afronden contact moment, registreren in CJ</h1>
        <p className='mb-4'>Het ICO/MDO besluit wordt opgenomen in het dossier. In ICO/MDO wordt onderbouwd waarom dit besluit genomen is.</p> 
        <div className="flex flex-col">
        <button className='mb-2 p-4 bg-pink-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties/monitoringsafspraken")}>Volgende</button>
        <button className='w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties")}>Terug</button>
        </div>
      </div>
    );
  }

export default AfrondingContact;