import React from "react";
import { useNavigate } from "react-router-dom";

function WelVeiligheidsVoorwaarden() {
    const navigate = useNavigate();
    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">Wel veiligheidsvoorwaarden en -afspraken nodig. Monitoring na 3 en 12 maanden</h1>
        <button class='mb-2 mt-4 font-bold text-left'>Wanneer Monitoren</button>
            <ul class='ml-4 mb-6 list-disc'>
                <li>Na 3 en 12 maanden</li>
                <li>Indien extra monitoringsmomenten wenselijk zijn kan dit alleen in ICO/MDO worden besloten. </li>
                <li>Monitoringsfase duurt <u>altijd</u> minimaal 1 jaar.</li>
                <li>Let op dat indien er meldingen in de casus staan van minder dan een half jaar terug, de situatie dus niet voor langere periode veilig is en er een nieuw monitoringsmoment moet worden gepland, minimaal 6 maanden na de laatste melding.</li>
            </ul>
        <div className="flex flex-col">
            <button className='mb-2 p-4 bg-orange-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/nieuwe-melding")}>Nieuwe melding</button>
            <button className='mb-2 p-4 bg-yellow-300 rounded-md border-4 border-black font-medium' onClick={() => navigate("/direct-betrokkenen-belt-vt")}>Direct betrokkene belt VT</button>
            <button className='mb-2 p-4 bg-blue-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/ketenpartner-belt-vt")}>Ketenpartner (KP) belt VT</button>
            <button className='mb-2 p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden")}>Volgende</button>
            <button className='mt-2 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema")}>Terug</button>        
        </div>
      </div>
    );
  }

export default WelVeiligheidsVoorwaarden;