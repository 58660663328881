import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 

function MonitoringNieuweKP() {
    const navigate = useNavigate();

    useEffect(() => {
      document.body.style.backgroundColor = colors.pink;
      return () => {
        document.body.style.backgroundColor = "";
      };
    }, []);

    return (
        <div className="wrapper">
            <h1 className="text-2xl mb-4">Monitoren bij nieuwe KP (bespreken met DB)</h1>
            <button class='mb-2 mt-4 font-bold text-left'>Monitoren bij andere hulpverlening/nieuwe ketenpartner dan afgesproken in eind ICO/MDO.</button>
            <ul class='ml-4 list-disc'>
                <li className='mb-2'>VT neemt in ICO/MDO besluit om wel/niet nieuwe ketenpartner te benaderen tijdens monitoringsfase. Er moet besloten worden of alleen monitoren bij directbetrokkenen voldoende is of dat het noodzakelijk is om bij de nieuwe ketenpartner te monitoren.</li>
                <li className='mb-2'>VT bespreekt vervolgens met directbetrokkene waarom VT contact wil opnemen met nieuwe ketenpartner en vraagt of directbetrokkene hier bezwaar tegen heeft</li>
                <li className='mb-2'>VT neemt met instemming directbetrokkenen contact op met nieuwe ketenpartner. Het doel van dit contact is alleen gericht op het verkrijgen van informatie over de reeds gestelde veiligheidsvoorwaarden.</li>
            </ul>
            <p className="mb-4">Een voorbeeld hiervan zou kunnen zijn: VT heeft overgedragen aan Lokaal Team. Lokaal Team heeft specialistische hulp ingezet en zelf afgesloten</p>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties")}>Terug</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/")}>Terug naar Startscherm</button>
      </div>
    );
  }

export default MonitoringNieuweKP;