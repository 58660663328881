import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 


function OverdrachtspartijBeltOverVeiligheidsvoorwaarden() {
    const navigate = useNavigate();

    useEffect(() => {
      document.body.style.backgroundColor = colors.blue;
      return () => {
        document.body.style.backgroundColor = "";
      };
    }, []);

    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">Overdrachtspartij belt over opgestelde veiligheidsvoorwaarden en/of - afspraken</h1>
        <div className="flex flex-col">
            <button className='mb-2 p-4 bg-blue-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/overdrachtspartij-belt-vt/overdrachtspartij-belt-over-veiligheidsafspraken/vt-denkt-mee")}>Volgende</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/overdrachtspartij-belt-vt")}>Terug</button>
        </div>
      </div>
    );
  }

export default OverdrachtspartijBeltOverVeiligheidsvoorwaarden;