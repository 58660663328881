import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 

function AanpassenVeiligheidsafspraken() {
    const navigate = useNavigate();

    useEffect(() => {
      document.body.style.backgroundColor = colors.pink;
      return () => {
        document.body.style.backgroundColor = "";
      };
    }, []);

    return (
        <div className="wrapper">
            <h1 className="text-2xl mb-4">VT denkt mee over aanpassen veiligheidsafspraken. Indien dit nodig is kan dit binnen monitoring</h1>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties/vt-meedenken")}>Terug</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/")}>Terug naar Startscherm</button>
      </div>
    );
  }

export default AanpassenVeiligheidsafspraken;