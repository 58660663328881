import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 


function DBKrijgtAntwoord() {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = colors.yellow;
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

    return (
      <div className="wrapper">
            <h1 className="text-2xl mb-4">DB krijgt antwoord op de gestelde vraag en/of DB doet volgens protocol een melding bij VT.</h1>
            <p className="mb-4">Alle contacten met DB moeten vastgelegd worden in een contactjournaal tijdens monitorfase. Leg ook vast wat de vervolgafspraak is.</p>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/direct-betrokkenen-belt-vt/db-belt-met-zorgen")}>Terug</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/")}>Terug naar Startscherm</button>
      </div>
    );
  }

export default DBKrijgtAntwoord;