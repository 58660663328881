import React from "react";
import { useNavigate } from "react-router-dom";

function BesluitRVKGaatOnderzoeken() {
    const navigate = useNavigate();
    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">Raad besluit tot onderzoek. VT probeer zicht op veiligheid te beleggen bij zorgpartij of houdt hier zelf zicht op tijdens onderzoek Raad. Wordt besloten tot maatregel?</h1>
        <button class='mb-2 mt-4 font-bold text-left'>Zicht op veiligheid tijdens onderzoek RVK: </button>
            <ul class='ml-4 mb-6 list-disc'>
                <li>Tijdens MDO/ICO afspreken hoe vaak en wanneer er contact opgenomen wordt en met wie. Uitgangspunt is dat we met alle DB bellen.</li>
                <li>RVK informeren als zicht op veiligheid niet lukt, indien DB niet wil samenwerken.</li>
                <li>VT zoekt actief contact met het gezin om te kijken i.v.m. monitoren gestelde veiligheidsafspraken worden nageleefd. Indien dit niet lukt HA of politie of school alert maken en afspreken dat ze VT informeren als ze zich zorgen maken. Deze afspraak kan ook gemaakt worden met DB.</li>
                <li>Bij nieuwe melding/ontwikkelingen die schadelijk zijn, eerst ICO/MDO evt. daarna neemt VT contact op met RVK i.v.m. nieuwe ontwikkelingen en evt. spoedonderzoek. </li>
            </ul>
        <div className="flex flex-col">
            <button className='mb-2 p-4 bg-green-600 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/vto-ingediend-bij-jbt/besluit-rvk-gaat-onderzoeken/ots-uitgesproken")}>Ja</button>
            <button className='mb-2 p-4 bg-red-600 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/vto-ingediend-bij-jbt/besluit-rvk-gaat-onderzoeken/geen-maatregel-opgelegd")}>Nee</button>
            <button className='mt-2 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/vto-ingediend-bij-jbt")}>Terug</button>        
        </div>
      </div>
    );
  }

export default BesluitRVKGaatOnderzoeken;