import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 

function Overdracht() {
    const navigate = useNavigate();

    useEffect(() => {
      document.body.style.backgroundColor = colors.orange;
      return () => {
        document.body.style.backgroundColor = "";
      };
    }, []);

    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">Overdracht</h1>
        <p className='mb-4'>Directe overdracht (door triage) aan overdrachtspartij. Veiligheidsvoorwaarden & veiligheidsafspraken + monitoringsmomenten blijven gelijk.</p>
        <p className="mb-4">De overdrachtspartij kan een ondersteuningsverzoek bij VT doen indien nodig. (Zie blauw schema ivm afwegingen)</p> 
        <div className="flex flex-col">
        <button className='mb-2 p-4 bg-blue-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/overdrachtspartij-belt-vt/overdrachtspartij-heeft-ondersteuning-vt-nodig")}>Meer informatie over ondersteuningsverzoek</button>
        <button className='w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/nieuwe-melding/triage-doet-veiligheidsbeoordeling/dezelfde-soort")}>Terug</button>
        </div>
      </div>
    );
  }

export default Overdracht;