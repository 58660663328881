import React from "react";
import { useNavigate } from "react-router-dom";

function OTSUitgesproken() {
    const navigate = useNavigate();
    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">Maatregel wordt uitgesproken. VT draagt over aan GI en sluit dossier, zonder monitoring</h1>
        <div className="flex flex-col">
            <button className='mt-2 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/vto-ingediend-bij-jbt/besluit-rvk-gaat-onderzoeken")}>Terug</button>  
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/")}>Terug naar Startscherm</button>      
        </div>
      </div>
    );
  }

export default OTSUitgesproken;