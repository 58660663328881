import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 

function VTSchatIn() {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = colors.yellow;
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

    return (
      <div className="wrapper">
            <h1 className="text-2xl mb-4">ICO/MDO VT schat in, (evt in overleg met betrokken ketenpartner) of dit meegenomen kan worden in het hulpverleningstraject</h1>
            <button className='mb-2 p-4 bg-green-600 rounded-md border-4 border-black font-medium' onClick={() => navigate("/direct-betrokkenen-belt-vt/db-verwezen-naar-ketenpartner")}>Ja</button>
            <button className='mb-2 p-4 bg-red-600 rounded-md border-4 border-black font-medium' onClick={() => navigate("/direct-betrokkenen-belt-vt/vt-schat-in/nieuwe-melding-aanmaken")}>Nee</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/direct-betrokkenen-belt-vt/db-belt-met-zorgen")}>Terug</button>
      </div>
    );
  }

export default VTSchatIn;