import React from "react";
import { useNavigate } from "react-router-dom";

function MonitoringAfsluiten() {
    const navigate = useNavigate();
    return (
        <div className="wrapper">
          <h1 className="text-2xl mb-4">Monitoring afsluiten.</h1>
          <p class='ml-4 mb-6'>VT stuurt brief ivm definitieve afronding bemoeienis naar direct betrokkenen en overdrachtspartij(en). Samenvatting van monitoring toevoegen aan verrijking.</p>
          <div className="flex flex-col">
            <button className='mb-2 p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden/monitoringsmoment-afsluiten/monitoring-na-12-maanden/contact-met-overdrachtspartij-p12/voldaan-aan-veiligheidsvoorwaarden-p12")}>Terug</button>
            <button className='mb-2 p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/")}>Terug naar Startscherm</button>
          </div>
      </div>
    );
  }

export default MonitoringAfsluiten;