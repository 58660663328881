import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 

function KPBeltNieuweZorgen() {
    const navigate = useNavigate();

    useEffect(() => {
      document.body.style.backgroundColor = colors.blue;
      return () => {
        document.body.style.backgroundColor = "";
      };
    }, []);
    
    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">KP belt met nieuw zorgen.</h1>,
        <div className="flex flex-col w-full">
            <button className='mb-2 w-full p-4 bg-green-600 rounded-md border-4 border-black font-medium' onClick={() => navigate("/ketenpartner-belt-vt/kp-belt-over-veiligheidsvoorwaarden/kp-belt-nieuwe-zorgen/kp-kan-zorgen-melden")}>Ja</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/ketenpartner-belt-vt/kp-belt-over-veiligheidsvoorwaarden")}>Terug</button>
        </div>
      </div>
    );
  }

export default KPBeltNieuweZorgen; 