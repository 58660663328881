import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../colors.ts'; 


function NieuweMelding() {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = colors.orange;
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);
  
    return (
      <div className="wrapper">
            <h1 className="text-2xl mb-4">Melding komt binnen tijdens monitoring</h1>
            <button className='mb-2 w-full p-4 bg-orange-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/nieuwe-melding/triage-doet-veiligheidsbeoordeling")}>Volgende</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/")}>Terug naar Startscherm</button>
      </div>
    );
  }

export default NieuweMelding;

//border-b-4 border-solid border-orange-500