import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Stroomschema from "./pages/Stroomschema";
import KetenpartnerBeltVT from "./pages/ketenpartner-belt-vt";
import MActies from "./pages/mogelijke-acties";
import OverdrachtspartijBeltVT from "./pages/overdrachtspartij-belt-vt";
import NieuweMelding from "./pages/nieuwe-melding";
import ExtraMonitoring from "./pages/mogelijke-acties/extra-monitoring";
import AfrondenContactMoment from "./pages/mogelijke-acties/afronden-contact-moment";
import AanmakenAdvies from "./pages/mogelijke-acties/aanmaken-veiligheidsafspraken";
import AmbtshalveMelding from "./pages/mogelijke-acties/ambtshalve-melding";
import MonitoringNieuweKP from "./pages/mogelijke-acties/monitoring-nieuwe-kp";
import VTMeedenken from "./pages/mogelijke-acties/vt-meedenken";
import MonitoringsAfspraken from "./pages/mogelijke-acties/monitoringsafspraken";
import OverdrachtspartijBeltOverVeiligheidsvoorwaarden from "./pages/overdrachtspartij-belt-vt/overdrachtspartij-belt-over-veligheidsvoorwaarden.js";
import VTDenktMee from "./pages/overdrachtspartij-belt-vt/vt-denkt-mee";
import OverdrachtspartijHeeftOndersteuningVTNodig from "./pages/overdrachtspartij-belt-vt/overdrachtpartij-heeft-ondersteuning-vt-nodig";
import OverdrachtspartijIntern from "./pages/overdrachtspartij-belt-vt/overdrachtspartij-intern";
import VeiligheidsvoorwaardenBlijven from "./pages/overdrachtspartij-belt-vt/veiligheidsvoorwaarden-blijven";
import BuitenMonitoring from "./pages/overdrachtspartij-belt-vt/buiten-monitoring";
import KPBeltOverVeiligheidsvoorwaarden from "./pages/ketenpartner-belt-vt/kp-belt-over-veiligheidsvoorwaarden";
import KPBeltNieuweZorgen from "./pages/ketenpartner-belt-vt/kp-belt-nieuwe-zorgen";
import KPWordtVerwezen from "./pages/ketenpartner-belt-vt/kp-wordt-verwezen-naar-overdrachtspartij";
import KPKanZorgenMelden from "./pages/ketenpartner-belt-vt/kp-kan-zorgen-melden";
import TriageDoetVeiligheidsbeoordeling from "./pages/nieuwe-melding/triage-doet-veiligheidsbeoordeling";
import DezelfdeSoort from "./pages/nieuwe-melding/dezelfde-soort";
import Overdracht from "./pages/nieuwe-melding/overdracht";
import CasushouderOverlegdMetOverdrachtspartij from "./pages/nieuwe-melding/casushouder-overlegd-met-overdrachtspartij";
import TriageBesluit from "./pages/nieuwe-melding/triagebesluit";
import CasushouderKeuzeVervolgstappen from "./pages/nieuwe-melding/casushouder-keuze-vervolgstappen";
import ContactOpnemenBetrokkenen from "./pages/nieuwe-melding/contact-opnemen-betrokkenen";
import IcoMdoDienstKiezen from "./pages/nieuwe-melding/ico-mdo-dienst-kiezen";
import MonitoringsfaseAfsluiten from "./pages/nieuwe-melding/monitoringfase-afsluiten";
import DirectbetrokkenenBeltVt from "./pages/direct-betrokkenen-belt-vt";
import DBVerwezenNaarKetenpartner from "./pages/direct-betrokkenen-belt-vt/db-verwezen-naar-ketenpartner";
import VTSchatIn from "./pages/direct-betrokkenen-belt-vt/vt-schat-in";
import NieuweMeldingAanmaken from "./pages/direct-betrokkenen-belt-vt/nieuwe-melding-aanmaken";
import ContactInContactJouraalNoteren from "./pages/direct-betrokkenen-belt-vt/contact-in-contactjournaal-noteren";
import OpstellenVeiligheidsafspraken from "./pages/mogelijke-acties/opstellen-veiligheidsafspraken";
import AanpassenVeiligheidsafspraken from "./pages/mogelijke-acties/aanpassen-veiligheidsafspraken";
import WelVeiligheidsVoorwaarden from "./pages/stroomschema/wel-veiligheidsvoorwaarden";
import GeenVeiligheidsVoorwaarden from "./pages/stroomschema/geen-veiligheidsvoorwaarden";
import VTOIngediendBijJBT from "./pages/stroomschema/vto-ingediend-bij-jbt";
import BesluitInzettenPreventief from "./pages/stroomschema/besluit-inzetten-preventief";
import BesluitRVKGaatOnderzoeken from "./pages/stroomschema/besluit-rvk-gaat-onderzoeken";
import GeenMaatregelOpgelegd from "./pages/stroomschema/geen-maatregel-opgelegd";
import OTSUitgesproken from "./pages/stroomschema/ots-uitgesproken";
import MonitoringNa3Maanden from "./pages/stroomschema/monitoring-na-3-maanden";
import ContactMetOVerdrachtspartij from "./pages/stroomschema/contact-met-overdrachtspartij";
import VoldaanAanVeiligheidsvoorwaarden from "./pages/stroomschema/voldaan-aan-veiligheidsvoorwaarden";
import ICOMDO from "./pages/stroomschema/ico-mdo";
import MonitoringsmomentAfsluiten from "./pages/stroomschema/monitoringsmoment-afsluiten";
import MonitoringNa12Maanden from "./pages/stroomschema/monitoring-na-12-maanden";
import ContactMetOVerdrachtspartijP12 from "./pages/stroomschema/contact-met-overdrachtspersoon-p12";
import VoldaanAanVeiligheidsvoorwaardenP12 from "./pages/stroomschema/voldaan-aan-veiligheidsvoorwaarden-p12";
import ICOMDOP12 from "./pages/stroomschema/ico-mdo-p12";
import MonitoringAfsluiten from "./pages/stroomschema/monitoring-afsluiten";
import DBbeltmetZorgen from "./pages/direct-betrokkenen-belt-vt/db-belt-met-zorgen";
import DBKrijgtAntwoord from "./pages/direct-betrokkenen-belt-vt/db-krijgt-antwoord";
import KPBeltMetDB from "./pages/ketenpartner-belt-vt/Kp-bespreekt-met-db";
import OverdrachtspartijBeltMetDB from './pages/overdrachtspartij-belt-vt/overdrachtpartij-belt-met-db'

function App() {

  return (
    <Router className="flex-row content-center align-middle">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/stroomschema' element={<Stroomschema />} />
        <Route path='/stroomschema/wel-veiligheidsvoorwaarden'  element={<WelVeiligheidsVoorwaarden />} />
        <Route path='/stroomschema/geen-veiligheidsvoorwaarden' element={<GeenVeiligheidsVoorwaarden />} />
        <Route path='/stroomschema/vto-ingediend-bij-jbt' element={<VTOIngediendBijJBT />} />
        <Route path='/stroomschema/vto-ingediend-bij-jbt/besluit-inzetten-preventief-toezicht' element={<BesluitInzettenPreventief />} />
        <Route path='/stroomschema/vto-ingediend-bij-jbt/besluit-rvk-gaat-onderzoeken' element={<BesluitRVKGaatOnderzoeken />} />
        <Route path='/stroomschema/vto-ingediend-bij-jbt/besluit-rvk-gaat-onderzoeken/geen-maatregel-opgelegd' element={<GeenMaatregelOpgelegd />} />
        <Route path='/stroomschema/vto-ingediend-bij-jbt/besluit-rvk-gaat-onderzoeken/ots-uitgesproken' element={<OTSUitgesproken />} />
        <Route path='/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden' element={<MonitoringNa3Maanden />} />
        <Route path='/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij' element={<ContactMetOVerdrachtspartij />} />
        <Route path='/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden' element={<VoldaanAanVeiligheidsvoorwaarden />} />
        <Route path='/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden/ico-mdo' element={<ICOMDO />} />
        <Route path='/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden/monitoringsmoment-afsluiten' element={<MonitoringsmomentAfsluiten />} />
        <Route path='/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden/monitoringsmoment-afsluiten/monitoring-na-12-maanden' element={<MonitoringNa12Maanden />} />
        <Route path='/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden/monitoringsmoment-afsluiten/monitoring-na-12-maanden/contact-met-overdrachtspartij-p12' element={<ContactMetOVerdrachtspartijP12 />} />
        <Route path='/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden/monitoringsmoment-afsluiten/monitoring-na-12-maanden/contact-met-overdrachtspartij-p12/voldaan-aan-veiligheidsvoorwaarden-p12' element={<VoldaanAanVeiligheidsvoorwaardenP12 />} />
        <Route path='/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden/monitoringsmoment-afsluiten/monitoring-na-12-maanden/contact-met-overdrachtspartij-p12/voldaan-aan-veiligheidsvoorwaarden-p12/ico-mdo-p12' element={<ICOMDOP12 />} />
        <Route path='/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden/monitoringsmoment-afsluiten/monitoring-na-12-maanden/contact-met-overdrachtspartij-p12/voldaan-aan-veiligheidsvoorwaarden-p12/monitoring-afsluiten' element={<MonitoringAfsluiten />} />
        <Route path='/nieuwe-melding' element={<NieuweMelding />} />
        <Route path='/nieuwe-melding/triage-doet-veiligheidsbeoordeling' element={<TriageDoetVeiligheidsbeoordeling />} />
        <Route path='/nieuwe-melding/triage-doet-veiligheidsbeoordeling/dezelfde-soort' element={<DezelfdeSoort />} />
        <Route path='/nieuwe-melding/triage-doet-veiligheidsbeoordeling/dezelfde-soort/overdracht' element={<Overdracht />} />
        <Route path='/nieuwe-melding/triage-doet-veiligheidsbeoordeling/dezelfde-soort/casushouder-overlegd-met-overdrachtspartij' element={<CasushouderOverlegdMetOverdrachtspartij />} />
        <Route path='/nieuwe-melding/triage-doet-veiligheidsbeoordeling/dezelfde-soort/casushouder-overlegd-met-overdrachtspartij/triagebesluit' element={<TriageBesluit />} />
        <Route path='/nieuwe-melding/triage-doet-veiligheidsbeoordeling/dezelfde-soort/casushouder-overlegd-met-overdrachtspartij/triagebesluit/casushouder-keuze-vervolgstappen' element={<CasushouderKeuzeVervolgstappen />} />
        <Route path='/nieuwe-melding/triage-doet-veiligheidsbeoordeling/dezelfde-soort/casushouder-overlegd-met-overdrachtspartij/triagebesluit/casushouder-keuze-vervolgstappen/contact-opnemen-betrokkenen' element={<ContactOpnemenBetrokkenen />} />
        <Route path='/nieuwe-melding/triage-doet-veiligheidsbeoordeling/dezelfde-soort/casushouder-overlegd-met-overdrachtspartij/triagebesluit/casushouder-keuze-vervolgstappen/contact-opnemen-betrokkenen/ico-mdo-dienst-kiezen' element={<IcoMdoDienstKiezen />} />
        <Route path='/nieuwe-melding/triage-doet-veiligheidsbeoordeling/dezelfde-soort/casushouder-overlegd-met-overdrachtspartij/triagebesluit/casushouder-keuze-vervolgstappen/contact-opnemen-betrokkenen/ico-mdo-dienst-kiezen/monitoringsfase-afsluiten' element={<MonitoringsfaseAfsluiten />} />
        <Route path='/direct-betrokkenen-belt-vt' element={<DirectbetrokkenenBeltVt />} />
        <Route path='/direct-betrokkenen-belt-vt/db-belt-met-zorgen' element={<DBbeltmetZorgen />} />
        <Route path='/direct-betrokkenen-belt-vt/db-belt-met-zorgen/db-krijgt-antwoord' element={<DBKrijgtAntwoord />} />
        <Route path='/direct-betrokkenen-belt-vt/db-verwezen-naar-ketenpartner' element={< DBVerwezenNaarKetenpartner/>}></Route>
        <Route path='/direct-betrokkenen-belt-vt/vt-schat-in' element={<VTSchatIn />} />
        <Route path='/direct-betrokkenen-belt-vt/db-verwezen-naar-ketenpartner/contact-in-contactjournaal-noteren' element={<ContactInContactJouraalNoteren />} />
        <Route path='/direct-betrokkenen-belt-vt/vt-schat-in/nieuwe-melding-aanmaken' element={<NieuweMeldingAanmaken />} />
        <Route path='/ketenpartner-belt-vt' element={<KetenpartnerBeltVT />} />
        <Route path='/ketenpartner-belt-vt/kp-belt-met-db' element={<KPBeltMetDB />} />
        <Route path='/ketenpartner-belt-vt/kp-belt-over-veiligheidsvoorwaarden' element={<KPBeltOverVeiligheidsvoorwaarden />} />
        <Route path='/ketenpartner-belt-vt/kp-belt-over-veiligheidsvoorwaarden/kp-belt-nieuwe-zorgen' element={<KPBeltNieuweZorgen />} />
        <Route path='/ketenparter-belt-vt/kp-belt-over-veiligheidsvoorwaarden/kp-wordt-verwezen-naar-overdrachtspartij' element={< KPWordtVerwezen />} />
        <Route path='/ketenpartner-belt-vt/kp-belt-over-veiligheidsvoorwaarden/kp-belt-nieuwe-zorgen/kp-kan-zorgen-melden' element={<KPKanZorgenMelden />} />
        <Route path='/overdrachtspartij-belt-vt' element={<OverdrachtspartijBeltVT />} />
        <Route path='/overdrachtspartij-belt-vt/overdrachtpartij-belt-met-db' element={<OverdrachtspartijBeltMetDB />} />
        <Route path='/overdrachtspartij-belt-vt/overdrachtspartij-belt-over-veiligheidsafspraken' element={<OverdrachtspartijBeltOverVeiligheidsvoorwaarden />} />
        <Route path="/overdrachtspartij-belt-vt/overdrachtspartij-belt-over-veiligheidsafspraken/vt-denkt-mee" element={<VTDenktMee />} />
        <Route path='/overdrachtspartij-belt-vt/overdrachtspartij-heeft-ondersteuning-vt-nodig' element={<OverdrachtspartijHeeftOndersteuningVTNodig />} />
        <Route path='/overdrachtspartij-belt-vt/overdrachtspartij-heeft-ondersteuning-vt-nodig/overdrachtspartij-intern' element={<OverdrachtspartijIntern />} />
        <Route path='/overdrachtspartij-belt-vt/overdrachtspartij-heeft-ondersteuning-vt-nodig/overdrachtspartij-intern/veiligheidsvoorwaarden-blijven' element={<VeiligheidsvoorwaardenBlijven />} />
        <Route path='/overdrachtspartij-belt-vt/overdrachtspartij-heeft-ondersteuning-vt-nodig/buiten-monitoring' element={<BuitenMonitoring />} />
        <Route path='/mogelijke-acties' element={<MActies />} />
        <Route path='/mogelijke-acties/afronden-contact-moment' element={<AfrondenContactMoment />} />
        <Route path='/mogelijke-acties/monitoringsafspraken' element={<MonitoringsAfspraken/>}  />
        <Route path='/mogelijke-acties/extra-monitoring' element={<ExtraMonitoring />} />
        <Route path='/mogelijke-acties/aanmaken-advies' element={<AanmakenAdvies />} />
        <Route path='/mogelijke-acties/ambtshalve-melding' element={<AmbtshalveMelding />} />
        <Route path='/mogelijke-acties/monitoren-bij-nieuwe-kp' element={<MonitoringNieuweKP/>} />
        <Route path='/mogelijke-acties/vt-meedenken' element={<VTMeedenken />} />
        <Route path='/mogelijke-acties/vt-meedenken/opstellen-veiligheidsafspraken' element={<OpstellenVeiligheidsafspraken />} />
        <Route path='/mogelijke-acties/vt-meedenken/aanpassen-veiligheidsafspraken' element={<AanpassenVeiligheidsafspraken />} />
      </Routes>
    </Router>
  );
}

export default App;
