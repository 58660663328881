import React from "react";
import { useNavigate } from "react-router-dom";

function BesluitInzettenPreventief() {
    const navigate = useNavigate();
    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">Raad ziet onvoldoende gronden voor VTO/VOTS of onderzoek van Raad leidt niet tot beschikking rechter voor beschermingsmaatregel.</h1>
        <div className="flex flex-col">
        <button className='mb-2 p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/vto-ingediend-bij-jbt/besluit-rvk-gaat-onderzoeken/geen-maatregel-opgelegd")}>Volgende</button>
            <button className='mt-2 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/vto-ingediend-bij-jbt")}>Terug</button>        
        </div>
      </div>
    );
  }

export default BesluitInzettenPreventief;