import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 



function OverdrachtspartijIntern() {
    const navigate = useNavigate();
    
    useEffect(() => {
      document.body.style.backgroundColor = colors.blue;
      return () => {
        document.body.style.backgroundColor = "";
      };
    }, []);

    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">Uitgangspunt: overdrachtspartij moet dit eerst intern, binnen eigen professionele organisatie oppakken.</h1>
        <button class='mb-2 mt-4 font-bold text-left'>Daarna deze opties:</button>
            <ul class='ml-4 mb-6 list-decimal'>
                <li>VT adviseert op afstand</li>
                <li>VT sluit op verzoek overdrachtspartij aan bij gesprek met DB</li>
            </ul>
        <div className="flex flex-col">
            <button className='mb-2 p-4 bg-blue-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/overdrachtspartij-belt-vt/overdrachtspartij-heeft-ondersteuning-vt-nodig/overdrachtspartij-intern/veiligheidsvoorwaarden-blijven")}>Volgende</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/overdrachtspartij-belt-vt/overdrachtspartij-heeft-ondersteuning-vt-nodig")}>Terug</button>
        </div>
      </div>
    );
  }

export default OverdrachtspartijIntern;