import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../colors.ts'; 

function Macties() {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = colors.pink;
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">Mogelijke acties, besluit hiervoor nemen in ICO/MDO</h1>
        <div className="flex flex-col">
          <button className='mb-2 p-4 bg-pink-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties/afronden-contact-moment")}>Afronden contact moment</button>
          <button className='mb-2 p-4 bg-pink-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties/extra-monitoring")}>Extra Monitoren</button>
          {/* <button className='mb-2 p-4 bg-pink-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties/aanmaken-advies")}>Aanmaken Advies</button> */}
          <button className='mb-2 p-4 bg-pink-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties/ambtshalve-melding")}>Ambtshalve melding aanmaken</button>
          <button className='mb-2 p-4 bg-pink-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties/monitoren-bij-nieuwe-kp")}>Monitoren bij nieuwe KP</button>
          <button className='mb-2 p-4 bg-pink-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties/vt-meedenken")}>Vraag aan VT om mee te denken</button>
          <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/")}>Terug naar Startscherm</button>
        </div>
      </div>
    );
  }


export default Macties;