import React from "react";
import { useNavigate } from "react-router-dom";

function ICOMDO() {
    const navigate = useNavigate();
    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">ICO/MDO</h1>
        <button class='mb-2 mt-4 font-bold text-left'>Direct betrokkene heeft zich niet gehouden aan gestelde veiligheidsvoorwaarden, waardoor er onvoldoende herstel van de veiligheid plaatsvindt. In ICO/MDO overweegt VT een van onderstaande opties:</button>
            <ul class='ml-4 mb-6 list-disc'>
                <li>Eenmalig contact opnemen met direct betrokkenen om na te gaan of en hoe ze wel in beweging gebracht kunnen worden</li>
                <li>(Ambtshalve) melding, zodat VT opnieuw kan bekijken of er hulp nodig is en zo ja welke</li>
                <li>Bemoeienis met het gezin/huishouden afsluiten bij gebrek aan mogelijkheden om tot verbetering van de veiligheid te komen. Dit besluit tot afronding van de bemoeienis wordt volgens de richtlijn in paragraaf 2.2. van het handelingsprotocol genomen.</li>
            </ul>
        <button class='mb-2 mt-4 font-bold text-left'>Ketenpartner/overdrachtspartij heeft niet gedaan wat ze moesten doen.</button>
        <p class='ml-4 mb-6'>	Indien er niet aan de veiligheidsvoorwaarden is voldaan omdat de ketenpartner niet heeft gedaan wat ze moesten doen (bijvoorbeeld door hun wachtlijst) is dat de verantwoordelijkheid van de ketenpartner. VT gaat niet (in monitoring) de problemen van de ketenpartner 	oplossen. Indien VT van mening is dat de situatie, door het niet/onvoldoende handelen van de ketenpartner, onveilig blijft of onveiliger wordt moet VT actie ondernemen en schaalt intern in op.</p>
        <div className="flex flex-col">
            <button className='mb-2 p-4 bg-pink-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties")}>Volgende</button>
            <button className='mt-2 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden")}>Terug</button>        
        </div>
      </div>
    );
  }

export default ICOMDO;