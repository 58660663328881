import React from "react";
import { useNavigate } from "react-router-dom";

function GeenVeiligheidsVoorwaarden() {
    const navigate = useNavigate();
    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">Geen veiligheids- voorwaarden nodig.  Geen monitoring, dossier sluiten.</h1>
        <div className="flex flex-col">
            <button className='mt-2 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema")}>Terug</button>     
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/")}>Terug naar Startscherm</button>   
        </div>
      </div>
    );
  }

export default GeenVeiligheidsVoorwaarden;