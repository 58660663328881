import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 


function OverdrachtspartijBeltMetDB() {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = colors.blue;
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

    return (
      <div className="wrapper">
            <h1 className="text-2xl mb-4">Let op!</h1>
            <p className="mb-4">Overdrachtspartij bespreekt eerst met DB dat zij VT willen bellen. Indien DB niet instemt of het niet gevraagd is, dan kan VT in deze fase niet meedenken. Indien DB geen instemming heeft verleend: dan moet Overdrachtspartij afwegen wel/niet te melden bij VT.</p>
            <button className='mb-2 w-full p-4 bg-blue-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/overdrachtspartij-belt-vt/overdrachtspartij-heeft-ondersteuning-vt-nodig")}>Volgende</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/overdrachtspartij-belt-vt")}>Terug</button>
      </div>
    );
  }

export default OverdrachtspartijBeltMetDB;