import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 

function MonitoringsAfspraken() {
    const navigate = useNavigate();

    useEffect(() => {
      document.body.style.backgroundColor = colors.pink;
      return () => {
        document.body.style.backgroundColor = "";
      };
    }, []);

    return (
        <div className="wrapper">
            <h1 className="text-2xl mb-4">Monitoringsafspraken blijven ongewijzigd</h1>
            <p className='mb-4'>De monitoringsafspraken blijven ongewijzigd</p> 
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties/afronden-contact-moment")}>Terug</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/")}>Terug naar Startscherm</button>
      </div>
    );
  }

export default MonitoringsAfspraken;