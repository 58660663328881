import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 


function BuitenMonitoring() {
    const navigate = useNavigate();

    useEffect(() => {
      document.body.style.backgroundColor = colors.blue;
      return () => {
        document.body.style.backgroundColor = "";
      };
    }, []);

    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">Dit valt niet binnen de monitoring. Overdrachtspartij kan vragen om advies of ondersteuningsverzoek. Dit wordt opgeschreven in apart advies/ ondersteuningsverzoek.</h1>
        <div className="flex flex-col">
            <button className='mb-2 p-4 bg-pink-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties")}>Volgende</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/overdrachtspartij-belt-vt/overdrachtspartij-heeft-ondersteuning-vt-nodig")}>Terug</button>        
        </div>
      </div>
    );
  }

export default BuitenMonitoring;