import React from "react";
import { useNavigate } from "react-router-dom";

function ContactMetOVerdrachtspartij() {
    const navigate = useNavigate();
    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">Contact met overdrachtspartij en direct betrokkenen</h1>
        <button class='mb-2 mt-4 font-bold text-left'>Wat doe je tijdens monitoringsmoment: </button>
            <ul class='ml-4 mb-6 list-disc'>
                <li>Eerst monitoren bij regievoerder/overdrachtspartij</li>
                <li>Daarna monitoren bij volwassen direct betrokkenen</li>
                <li>Vervolgens monitoren bij kinderen vanaf 4 jr. VT moet dit ( in principe) zelf doen en kan dit niet overlaten aan betrokken hulpverleners </li>
            </ul>
        <button class='mb-2 mt-4 font-bold text-left'>Redenen om niet bij kinderen te monitoren:</button>
        <ul class='ml-4 mb-6 list-disc'>
            <li>Overdrachtspartij heeft goede band met kind en kan kindgesprek in kader van monitoring doen</li>
            <li>Eerder gesprek met kind heeft nauwelijks informatie opgeleverd en de verwachting is dat dit niet gewijzigd zal zijn (bijv. heel fantasierijk of jonge leeftijd)</li>
        </ul>
        <button class='mb-2 mt-4 font-bold text-left'>Aandachtspunten:</button>
        <ul class='ml-4 mb-6 list-disc'>
            <li>VT doet 3 contactpogingen bij direct betrokkenen (minimaal 2 vormen kiezen: bellen, mailen, appen, brief, onverwachts huisbezoek), anders ICO/MDO inplannen ivm    voortgang monitoring</li>
        </ul>
        <div className="flex flex-col">
            <button className='mb-2 p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden")}>Volgende</button>
            <button className='mt-2 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden")}>Terug</button>        
        </div>
      </div>
    );
  }

export default ContactMetOVerdrachtspartij;