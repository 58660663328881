import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 

function KPBeltOverVeiligheidsvoorwaarden() {
    const navigate = useNavigate();

    useEffect(() => {
      document.body.style.backgroundColor = colors.blue;
      return () => {
        document.body.style.backgroundColor = "";
      };
    }, []);

    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">KP belt over opgestelde veiligheidsvoorwaarden  en/of - afspraken</h1>
        <div className="flex flex-col">
            <button className='mb-2 p-4 bg-green-600 rounded-md border-4 border-black font-medium' onClick={() => navigate("/ketenparter-belt-vt/kp-belt-over-veiligheidsvoorwaarden/kp-wordt-verwezen-naar-overdrachtspartij")}>Ja</button>
            <button className='mb-2 p-4 bg-red-600 rounded-md border-4 border-black font-medium' onClick={() => navigate("/ketenpartner-belt-vt/kp-belt-over-veiligheidsvoorwaarden/kp-belt-nieuwe-zorgen")}>Nee</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/ketenpartner-belt-vt/kp-belt-met-db")}>Terug</button>
        </div>
      </div>
    );
  }

export default KPBeltOverVeiligheidsvoorwaarden;