import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../colors.ts'; 

function DirectbetrokkenenBeltVt() {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = colors.yellow;
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);


    return (
      <div className="wrapper">
            <h1 className="text-2xl mb-4">DB belt VT tijdens monitoringsfase</h1>
            <button className='mb-2 w-full p-4 bg-yellow-300 rounded-md border-4 border-black font-medium' onClick={() => navigate("/direct-betrokkenen-belt-vt/db-verwezen-naar-ketenpartner")}>DB belt over opgestelde veiligheidsvoorwaarden en/of - afspraken</button>
            <button className='mb-2 w-full p-4 bg-yellow-300 rounded-md border-4 border-black font-medium' onClick={() => navigate("/direct-betrokkenen-belt-vt/db-verwezen-naar-ketenpartner")}>DB belt ivm het (nog) niet starten van hulpverlening</button>
            <button className='mb-2 w-full p-4 bg-yellow-300 rounded-md border-4 border-black font-medium' onClick={() => navigate("/direct-betrokkenen-belt-vt/db-verwezen-naar-ketenpartner")}>DB belt omdat hij niet tevreden is over geboden hulp</button>
            <button className='mb-2 w-full p-4 bg-yellow-300 rounded-md border-4 border-black font-medium' onClick={() => navigate("/direct-betrokkenen-belt-vt/db-belt-met-zorgen")}>DB belt met zorgen over HG of KMH?</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/")}>Terug naar Startscherm</button>
      </div>
    );
  }

export default DirectbetrokkenenBeltVt;