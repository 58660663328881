import React from "react";
import { useNavigate } from "react-router-dom";

function Home() {
    const navigate = useNavigate();

    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">Welkom bij het Stroomschema</h1>
        <div className="flex flex-col">
          <button className='mb-2 p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema")}>Stroomschema monitoring</button>
          <button className='mb-2 p-4 bg-orange-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/nieuwe-melding")}>Nieuwe melding tijdens monitoring</button>
          <button className='mb-2 p-4 bg-yellow-300 rounded-md border-4 border-black font-medium' onClick={() => navigate("/direct-betrokkenen-belt-vt")}>Direct betrokkene belt VT tijdens monitoringsfase</button>
          <button className='mb-2 p-4 bg-blue-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/ketenpartner-belt-vt")}>Ketenpartner (KP) belt VT tijdens monitoringsfase</button>
          <button className='mb-2 p-4 bg-blue-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/overdrachtspartij-belt-vt")}>Overdrachtspartij belt VT tijdens monitoringsfase</button>
          <p>© Build by<a class='ml-1' href='https://dynative.co/'>Dynative</a>. All rights reserved.</p>
        </div>
      </div>
    );
  }

export default Home;