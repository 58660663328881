import React from "react";
import { useNavigate } from "react-router-dom";

function GeenMaatregelOpgelegd() {
    const navigate = useNavigate();
    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">Er is geen maatregel opgelegd. </h1>
        <button class='mb-2 mt-4 font-bold text-left'> VT kiest één van volgende mogelijkheden in MDO:</button>
            <ul class='ml-4 mb-6 list-disc'>
                <li>VT draagt over en start dienst monitoring</li>
                <li>Afsluiten bemoeienis VT</li>
            </ul>
        <button class='mb-2 mt-4 font-bold text-left'>Bij monitoring na JBT</button>
          <p class='mb-2'>Er zijn vier scenario’s bij bespreking verzoek tot onderzoek door VT naar de noodzaak voor hulpverlening in het kader van een beschermingsmaatregel (VTO of VOTS). Via JBT of 	rechtstreeks bij de Raad in geval van VOTS: </p>
            <ul class='ml-4 mb-6 list-decimal'>
                <li class='font-medium mb-2'>De raad besluit tot onderzoek.<br/>
                  <span class='font-normal'>Casusregie voor zicht op veiligheid wordt belegd bij zorgpartij tot uitkomst raadsonderzoek of besluit rechter. 
                  Bijvoorbeeld belegd bij GI (in vrijwillig kader), lokaal team of specialistische hulpverlener. Indien die niet beschikbaar is blijft VT als 
                  casushouder zicht op veiligheid houden gedurende raadsonderzoek (inclusief wachttijd start raadsonderzoek).</span>
                </li>
                <li class='font-medium mb-2'>De raad ziet onvoldoende gronden voor een VTO of VOTS, geen onderzoek<br/>
                  <span class='font-normal'>De Raad ziet onvoldoende gronden voor een VTO of VOTS,  Raad besluit na indienen VTO dat er geen onderzoek door Raad nodig is.
			            Dit is een nieuw beslismoment (MDO) over wat nodig is en aan wie wordt overgedragen. Als VT zicht op veiligheid heeft gehouden, dan draagt VT over en 
                  start dienst monitoren.</span>
                </li>
                <li class='font-medium mb-2'>Het onderzoek van de Raad leidt tot beschikking rechter voor beschermingsmaatregel.<br/>
                  <span class='font-normal'>Het onderzoek van de Raad leidt tot beschikking rechter voor beschermingsmaatregel. 
                  Overdracht van casushouder naar GI. VT monitort niet.</span>
                </li>
                <li class='font-medium mb-2'>Het onderzoek van de Raad leidt niet tot beschikking rechter voor beschermingsmaatregel.<br/>
                  <span class='font-normal'>Het onderzoek van de Raad leidt niet tot beschikking rechter voor beschermingsmaatregel. Dit is een nieuw beslismoment (MDO) over 
                  wat nodig is en aan wie wordt overgedragen. Als VT zicht op veiligheid heeft gehouden, dan draagt VT over. Daarna start dienst monitoren. </span>
                </li>
            </ul>
        <div className="flex flex-col">
          <button className='mb-2 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/vto-ingediend-bij-jbt/besluit-rvk-gaat-onderzoeken")}>Terug</button>  
          <button className='mb-2 p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/")}>Terug naar Startscherm</button>      
        </div>
      </div>
    );
  }

export default GeenMaatregelOpgelegd;