import React from "react";
import { useNavigate } from "react-router-dom";

function VoldaanAanVeiligheidsvoorwaardenP12() {
    const navigate = useNavigate();
    return (
        <div className="wrapper">
          <h1 className="text-2xl mb-4">Voldaan aan veiligheidsvoorwaarden?</h1>
          <p>Dossierverantwoordelijke beantwoordt deze vragen: </p>
          <button class='mb-2 mt-4 font-bold text-left'>Is er voldaan aan de veiligheidsvoorwaarden?</button>
            <ul class='ml-4 mb-4 list-disc'>
                <li>Hebben de veiligheidsvoorwaarden geleid tot directe veiligheid?</li>
                <li>Waren de afgelopen 6 maanden veilig? (m.a.w.: hebben er in de afgelopen 6 maanden geen onveilige situaties voorgedaan?) (in het geval van een nieuwe melding de afgelopen 6 maanden periode, in ICO/MDO besluiten wat deze nieuwe melding voor monitoring betekent)</li>
                <li>Is er aandacht voor herstel opgelopen schade?.</li>
            </ul>
          <p className="mb-6">Indien op alle vragen ‘ja’ het antwoord is, kan VT de monitoringsfase afsluiten en het dossier sluiten.</p>
          <div className="flex flex-col">
            <button className='mb-2 p-4 bg-green-600 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden/monitoringsmoment-afsluiten/monitoring-na-12-maanden/contact-met-overdrachtspartij-p12/voldaan-aan-veiligheidsvoorwaarden-p12/monitoring-afsluiten")}>Ja</button>
            <button className='mb-2 p-4 bg-red-600 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden/monitoringsmoment-afsluiten/monitoring-na-12-maanden/contact-met-overdrachtspartij-p12/voldaan-aan-veiligheidsvoorwaarden-p12/ico-mdo-p12")}>Nee</button>
            <button className='mb-2 p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden/monitoringsmoment-afsluiten/monitoring-na-12-maanden/contact-met-overdrachtspartij-p12")}>Terug</button>
          </div>
      </div>
    );
  }

export default VoldaanAanVeiligheidsvoorwaardenP12;