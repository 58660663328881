import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 

function VTMeedenken() {
    const navigate = useNavigate();

    useEffect(() => {
      document.body.style.backgroundColor = colors.pink;
      return () => {
        document.body.style.backgroundColor = "";
      };
    }, []);

    return (
        <div className="wrapper">
            <h1 className="text-2xl mb-4">Vraag aan VT is om mee te denken over twijfels rondom veiligheid en de veiligheids- voorwaarden</h1>
            <ul class='mb-4 ml-4 list-disc'>
                <li className="mb-2">VT past de gemaakte veiligheidsvoorwaarden in de dienst monitoring niet aan. Om een veiligheidsvoorwaarde aan te kunnen passen is een nieuwe melding (kan ook ambtshalve) nodig zodat na de veiligheidsbeoordeling opnieuw beoordeeld kan worden welke veiligheidsvoorwaarde(n) noodzakelijk zijn voor herstel van de veiligheid.</li>
                <li className="mb-2">VT kan de gemaakte veiligheidsafspraken wel aanpassen. Dit is geen nieuw uitgangspunt maar een concrete afspraak die voortvloeit uit de reeds opgestelde veiligheidsvoorwaarden. </li>
            </ul>
            <button className='w-full mb-2 p-4 bg-pink-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties/vt-meedenken/aanpassen-veiligheidsafspraken")}>Aanpassen veiligheidsafspraken</button>
            <button className='w-full mb-2 p-4 bg-pink-500 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties/vt-meedenken/opstellen-veiligheidsafspraken")}>Opstellen veiligheidsafspraken</button> 
            <button className='w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties")}>Terug</button>
      </div>
    );
  }

export default VTMeedenken;