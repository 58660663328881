import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 

function ExtraMonitoring() {
    const navigate = useNavigate();

    useEffect(() => {
      document.body.style.backgroundColor = colors.pink;
      return () => {
        document.body.style.backgroundColor = "";
      };
    }, []);

    return (
        <div className="wrapper">
            <h1 className="text-2xl mb-4">Extra monitoring</h1>
            <p className='mb-4'>Er kan wel extra worden gemonitord, echter alleen indien er sprake is van: </p> 
            <ul className='ml-4 list-disc'>
                <li className="mb-2">Er een nieuwe melding is binnengekomen, waardoor de termijn van 6 maanden stabiele veiligheid niet behaald kan worden zonder extra monitoringsmoment.</li>
                <li className="mb-2">Indien tijdens het laatste monitoringsmoment blijkt dat er nog niet aan de 4 onderdelen van de monitoringsvragen (zie aanvullende informatie punt 1) is voldaan, maar er wel voldoende 	vooruitgang te zien is ten aanzien van veiligheid en herstel van schade. Er kan dan een nieuw monitoringsmoment worden gepland (in ICO/MDO besluit nemen) maar bekijk wel goed of dit passend is bij de monitoringsfase.</li>
            </ul>
            <p className="mb-4">Extra monitoringsmomenten worden nu vaak ingezet om na te gaan of de hulpverlening reeds is gestart. Dit is niet het doel van monitoren. Het tijdig starten van hulpverlening is de verantwoordelijkheid van de overdrachtspartij en niet van VT.</p>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/mogelijke-acties")}>Terug</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/")}>Terug naar Startscherm</button>
      </div>
    );
  }

export default ExtraMonitoring;