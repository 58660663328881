import React from "react";
import { useNavigate } from "react-router-dom";

function VTOIngediendBijJBT() {
    const navigate = useNavigate();
    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">VTO ingediend bij JBT. Na JBT dienst O afsluiten en overdracht start. Wordt besloten tot onderzoek?</h1>
        <div className="flex flex-col">
            <button className='mb-2 p-4 bg-green-600 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/vto-ingediend-bij-jbt/besluit-rvk-gaat-onderzoeken")}>Ja</button>
            <button className='mb-2 p-4 bg-red-600 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/vto-ingediend-bij-jbt/besluit-inzetten-preventief-toezicht")}>Nee</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema")}>Terug</button>
        </div>
      </div>
    );
  }

export default VTOIngediendBijJBT;