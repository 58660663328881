import React from "react";
import { useNavigate } from "react-router-dom";

function MonitoringsmomentAfsluiten() {
    const navigate = useNavigate();
    return (
        <div className="wrapper">
          <h1 className="text-2xl mb-4">Dit monitoringsmoment afsluiten</h1>
          <div className="flex flex-col">
            <button className='mb-2 p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden/monitoringsmoment-afsluiten/monitoring-na-12-maanden")}>Volgende</button>
            <button className='mb-2 p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/stroomschema/wel-veiligheidsvoorwaarden/monitoring-na-3-maanden/contact-met-overdrachtspartij/voldaan-aan-veiligheidsvoorwaarden")}>Terug</button>
          </div>
      </div>
    );
  }

export default MonitoringsmomentAfsluiten;