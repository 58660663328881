import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { colors } from './../../colors.ts'; 

function KPKanZorgenMelden() {
    const navigate = useNavigate();

    useEffect(() => {
      document.body.style.backgroundColor = colors.blue;
      return () => {
        document.body.style.backgroundColor = "";
      };
    }, []);

    return (
      <div className="wrapper">
        <h1 className="text-2xl mb-4">KP kan zorgen melden bij VT conform werkwijze</h1>
        <p className="mb-4">Alle contacten moeten vastgelegd worden in een contactjournaal tijdens monitorfase. Leg ook vast wat de vervolgafspraak is.</p>
        <div className="flex flex-col">
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/ketenpartner-belt-vt/kp-belt-over-veiligheidsvoorwaarden/kp-belt-nieuwe-zorgen")}>Terug</button>
            <button className='mt-4 w-full p-4 bg-gray-200 rounded-md border-4 border-black font-medium' onClick={() => navigate("/")}>Terug naar Startscherm</button>
        </div>
      </div>
    );
  }

export default KPKanZorgenMelden;